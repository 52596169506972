import React from "react";

const PageCoverLogin = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageCoverLogin")
);
const PageVerify = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageVerify")
);
const PageCoverSignup = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageCoverSignup")
);
const PageUpdateCard = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageUpdateCard")
);
const PageCoverNewSub = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageCoverNewSub")
);
const PageCoverRePassword = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageCoverRePassword")
);
const PageCoverRePasswordRedefine = React.lazy(() =>
  import("./pages/Pages/AuthPages/PageCoverRePasswordRedefine")
);

const Apps = React.lazy(() => import("./pages/Apps/index"));
const Sobre = React.lazy(() => import("./pages/Apps/Sobre"));
const Termos = React.lazy(() => import("./pages/Apps/Termos"));
const Politica = React.lazy(() => import("./pages/Apps/Politica"));
const Ajuda = React.lazy(() => import("./pages/Apps/Ajuda"));
const Landing = React.lazy(() => import("./pages/Apps/Landing"));

//Account
const PageProfile = React.lazy(() =>
  import("./pages/Pages/Account/PageProfile")
);
const PageLead = React.lazy(() =>
  import("./pages/Apps/LeadPage")
);
const PagePayments = React.lazy(() =>
  import("./pages/Pages/Account/PagePayments")
);
const PageInvoice = React.lazy(() =>
  import("./pages/Pages/Account/PageInvoice")
);
const PageContactTwo = React.lazy(() =>
  import("./pages/Pages/Contact/PageContactTwo")
);

const routes = [
  {
    path: "/login",
    component: PageCoverLogin,
    isWithoutLayout: true,
  },
  {
    path: "/page-verify",
    component: PageVerify,
  },
  {
    path: "/registro",
    component: PageCoverSignup,
    isWithoutLayout: true,
  },
  {
    path: "/adicionar-cartao",
    component: PageUpdateCard,
    isWithoutLayout: true,
  },
  {
    path: "/registrar-novo-plano",
    component: PageCoverNewSub,
    isWithoutLayout: true,
  },
  {
    path: "/esqueci-minha-senha",
    component: PageCoverRePassword,
    isWithoutLayout: true,
  },
  {
    path: "/redefinir-senha/:email",
    component: PageCoverRePasswordRedefine,
    isWithoutLayout: true,
  },
  { path: "/", component: Apps, isTopbarDark: true },
  { path: "/sobre", component: Sobre, isTopbarDark: true },
  { path: "/termos-de-uso", component: Termos, isTopbarDark: true },
  { path: "/politica-de-privacidade", component: Politica, isTopbarDark: true },
  { path: "/ajuda", component: Ajuda, isTopbarDark: true },
  { path: "/perfil", component: PageProfile, isTopbarDark: true },
  { path: "/lead", component: PageLead },
  { path: "/assinatura", component: PagePayments, isTopbarDark: true },
  { path: "/page-invoice", component: PageInvoice, isTopbarDark: true },
  { path: "/contato", component: PageContactTwo, isTopbarDark: true },
  { component: Apps, isTopbarDark: true },
];

export default routes;
